import React from "react";
import { NavLink } from "react-router-dom";
import ssc from '../../../assets/images/home/clients/ssc.png'
import cow from '../../../assets/images/home/clients/cow.png'
import agribank from '../../../assets/images/home/clients/agribank.png'
import nored from '../../../assets/images/home/clients/nored.png'
import nip from '../../../assets/images/home/clients/nip.png'
import cenored from '../../../assets/images/home/clients/cenored.png'
import nampa from '../../../assets/images/home/clients/nampa.png'
import stimes from '../../../assets/images/home/clients/stimes.png'
import sanlam from '../../../assets/images/home/clients/sanlam.png'

export default class Home extends React.Component {

   state = {
      data: [
         {name:"Social Security Comission", url:ssc},
         {name:"City Of Windhoek", url:cow},
         {name:"AGRIBANK of Namibia", url:agribank},
         {name:"Northern Regional Electricity Distributor", url:nored},
         {name:"Namibian institute of Pathology", url:nip},
         {name:"Central Regional Electricity Distributor", url:cenored},
         {name:"Namibia Press Agency (NAMPA)", url:nampa},
         // {name:"The Southern Times", url:stimes},
         {name:"Sanlam", url:sanlam},
      ],
    };

   render() {
      var { data } = this.state
      return (
      	<div className="">
      	   <div className="contentBox home">
               <h1 className="textCenter">#1 Customer Experience Provider</h1>
                  <p>Our journey began as a software development firm, and over time, we've continued to evolve and stay ahead of the curve by focusing on cloud and scalable cloud-native applications. However, we didn't stop there. We ventured into electronics, hardware, and 3D prototyping, allowing us to develop cutting-edge queueing systems and self-service kiosks, as well as establish a thriving customer experience department.</p>               
                  <p>We firmly believe that to remain relevant in today's fast-paced world, one must embrace bold, unconventional ideas. That's why we're not afraid to think big and push boundaries. At our core, we are builders, and we're committed to delivering innovative solutions that exceed our clients' expectations.</p>
               <NavLink to="/contact/" className="get-stred-btn ">Get Started <i className="fa fa-angle-right"></i></NavLink>

               {/* OUR CLIENTS */}
            </div>
               <div className=" ourClients">
                  <div className="ourClientsContent textCenter">
                     <p className="preHeader">Who do we work with?</p>
                     <h1>Our clients.</h1>
                     <div className="clientsLogoContainer">
                        {data ? data.map(data => (
                           <div className="logoContainer">
                              <img title={data.name} alt={data.name} src={data.url} />
                           </div>                                 
                        )) : null }
                      </div>                        
                  </div>
               </div>
         </div>
      );
   }

}
