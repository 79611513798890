import React from "react"
import {Helmet} from "react-helmet"
//COMPONENTS     ///////////////////////////////////////////////////////////////////
import  Header from "../components/layout/Header";
import  Content from "../components/layout/Content";
import  Cover from "../components/layout/Cover";
import  Footer from "../components/layout/Footer";
import  Products from "../components/layout/Products";
import  Socials from "../components/layout/Socials";
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import cover from '../assets/images/queue-management/cover.jpg'
import '../assets/css/Pages.css';
///////////////////////////////////////////////////////////////////////////////////

import ReactGA from 'react-ga';
ReactGA.initialize('UA-127398027-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class QueueManagement extends React.Component {
	constructor(){
      super()
      this.state = {
				meta: {
					 title: "Averly | Queue Management Solution",
					 description: 'Averly queue management system is a ticketing system for virtual queues and management of "first come, first serve" linear queues in Namibia.',
					 canonical: "https://averly.com.na/queue-management/",
				},
         coverImage: cover,
         coverTitle: "Don't let your customers wait. Manage your queues better.",
         page: "QueueManagement",
         getStarted: true,
      }
   }

   componentWillMount(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
   }

   render() {
      return (
				<div>
					<Helmet>
						<title>{this.state.meta.title}</title>
	 					<meta name="description" content={this.state.meta.description} />
	 					<link rel="canonical" href={this.state.meta.canonical} />
					</Helmet>
					<Header />
					<Cover data={this.state} alt="customers in a queue" />
					<Content page={this.state.page} />
					<Products />
					<Socials />
					<Footer />
				</div>
      );
   }

}
