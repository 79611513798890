import React from "react";
import Mailto from 'react-protected-mailto'

import "../../../assets/css/Careers.css"

export default class DigitalSignage extends React.Component {
    constructor(){
        super();
        this.state = {
            listingIndex: 0
        }
    };

listings = [
    {
        jobTitle: 'Office Administrator',
        location: 'Windhoek',
        salary: '',
        jobType: 'Full-time',
        closingDate: '18 March 2024',
        short: "Office Administrator with excellent organizational and communication skills to manage daily operations in a fast-paced environment. The ideal candidate should have experience with office management, scheduling, and book keeping.",
        jobDescription: "We are seeking a highly organized and efficient office administrator and bookkeeper to join our team. The office administrator will be responsible for managing administrative duties such as managing office supplies, maintaining records, and coordinating with staff members, while also handling bookkeeping tasks such as maintaining financial records, processing invoices, and reconciling accounts. The ideal candidate will have excellent communication skills, be detail-oriented, and be able to prioritize tasks effectively.",
        responsibilities: [
            "Coordinate and prioritize tasks for staff members to ensure timely completion of projects and client deliverables.",
            "Maintain schedules and calendars, scheduling appointments, and reminding staff members of deadlines.",
            "Perform bookkeeping tasks such as inquiring & submitting of invoices, maintaining financial records, and reconciling accounts.            ",
            "Communicate with external vendors as required ",
            "Monitor incoming client queries and ensure prompt and professional responses.",
            "Manage company social media accounts (e.g., Facebook, Twitter, LinkedIn) including scheduling posts, monitoring engagement, and responding to comments and messages.",
            "Assist in the preparation and organization of meetings, including agenda creation and document distribution.",
            "Assist with scheduling of interviews ",
            "Manage office supplies and equipment, ensuring adequate stock levels and functionality.",
            "Oversee administrative processes and identify areas for improvement to streamline operations.",
            "With Management guidance plan staff retreats.",
            "Serve as a point of contact for internal dialogues and deliberations, demonstrating excellent communication skills and professionalism.",
            "Provide administrative support to senior management as needed.",
        ],
        requirements: [
            "+3 years in Administration and Operations role previously ",
            "Proven experience in administrative roles, preferably in a fast-paced environment.",
            "Strong organizational skills with the ability to multitask and prioritize effectively.",
            "Excellent communication skills, both written and verbal.",
            "Proficiency in office software (e.g., Microsoft Office Suite, Google Workspace)",
            "Proficiency in financial software applications (e.g. Quick books and SAGE).",
            "Ability to work independently and as part of a team.",
            "Be flexible to multitask effectively.",
            "Attention to detail and problem-solving skills.",
            "Prior experience in customer service or client-facing roles is a plus.",
            "Familiarity with social media management tools and best practices.",
            "Assert a level of leadership and be able to work with minimal supervision.",
        ],
        Preferred: [],
        conclusion: "If you are a highly organized and detail-oriented individual who is passionate about providing exceptional administrative and bookkeeping support, we encourage you to apply for this exciting opportunity.",
    }, 
    {
        jobTitle: 'Full-stack MERN Engineer',
        location: 'Windhoek',
        salary: '',
        jobType: 'Full-time',
        closingDate: 'Open-ended',
        short: "Fullstack MERN Engineer who is proficient in MongoDB, Express.js, React.js, and Node.js to design, develop and maintain web applications.",
        jobDescription: "We are seeking a Senior Software Engineer. The engineer will be collaborating closely with cross-functional teams to help meet product requirements. Our company develops queuing and self service solutions for corporates. This is a great opportunity for developers who enjoy working in a fast-paced environment that requires rapid iterations.",
        responsibilities: [
            "Develop scalable solutions from scratch and help improve existing applications",
            "End-to-end web architecture planning, design, development and implementation",
            "Work with a variety of systems and technology to address challenges from beginning to end",
            "Analyse and Develop Business Intelligence to help our customers make sense of their data",
            "Identify and mitigate performance bottlenecks",
            "Write and maintain high-performant code and participate in daily virtual stand-ups.",
        ],
        requirements: [
            "Bachelor’s/Master’s degree in Engineering, Computer Science (or equivalent experience)",
            "At least 4+ years of relevant experience as a fullstack React Engineer",
            "Extensive experience in React, Redux, Express.js, NEST.js, TypeScript, Sockets, NPM,  GIT and Node.js",
            "Database Knowledge in SQL & NoSQL databases",
            "Cloud Management - Docker, Kubernetes (managed cloud and on-premise with kubectl), Firebase, Serverless, scaling and RabbitMQ",
            "Comfortable with short sprints and a fast pace environment",
            "Fluent in verbal and written English",
        ],
        Preferred: [
            "JAVA would be an added advantage",
        ],
        conclusion: "If you are passionate about building high-quality web applications and have experience with the MERN stack, we would love to hear from you! Please submit your resume and a cover letter detailing your experience and why you think you are a good fit for this position.",
    },
    {
        jobTitle: 'Java/Kotlin Engineer',
        location: 'Windhoek',
        salary: '',
        jobType: 'Full-time',
        closingDate: 'Open-ended',
        short: "Java Engineer to design and develop high-quality software solutions. The ideal candidate should have strong proficiency in Java programming language, as well as experience with software development methodologies such as Agile and Scrum.",
        jobDescription: "We are seeking a talented Java/Kotlin Engineer to join our team. The successful candidate will be responsible for designing and implementing software solutions using Java and Kotlin programming languages. You will work closely with cross-functional teams to develop high-quality software products and maintain the existing ones.",
        responsibilities: [
            "Develop, test and maintain high-quality software applications using Java and Kotlin programming languages.",
            "Write clean, efficient, and well-documented code.",
            "Collaborate with cross-functional teams to understand business requirements and design solutions that meet those requirements.",
            "Troubleshoot and debug software issues to ensure that the applications are running smoothly.",
            "Participate in code reviews to ensure code quality and standards are maintained.",
            "Follow agile methodologies and best practices for software development.",
            "Continuously improve software development processes and practices to increase efficiency and productivity.",
            "Stay up-to-date with the latest trends and technologies in software development.",
        ],
        requirements: [
            "Bachelor's degree in Computer Science, Software Engineering or a related field.",
            "Proven experience as a Java/Kotlin Developer or Java/Kotlin Engineer with a strong understanding of object-oriented programming concepts.",
            "Experience with Java frameworks such as Spring, Hibernate, Struts, etc.",
            "Familiarity with web application development and web technologies such as HTML, CSS, JavaScript, etc.",
            "Knowledge of database technologies such as SQL, MySQL, Oracle, etc.",
            "Experience with Agile software development methodologies.",
            "Strong problem-solving skills and attention to detail.",
            "Ability to work independently and as part of a team.",
            "Excellent communication and interpersonal skills.",
            "Experience with Kotlin programming language.",
            "Experience with Maven build automation tool.",
        ],
        Preferred: [
            "Experience with cloud computing platforms such as AWS, Azure, or GCP.",
            "Familiarity with DevOps tools such as Jenkins, Git, Docker, Kubernetes, etc.",
            "Experience with microservices architecture and RESTful web services.",
            "Knowledge of other programming languages such as Python, Ruby, or Go.",
            "Experience with Maven build automation tool.",
        ],
        conclusion: "This is a full-time position with a competitive salary and benefits package. If you are passionate about Java/Kotlin development and want to work in a dynamic and challenging environment, please apply with your resume and cover letter.",
    },
    {
        jobTitle: 'IT/Support Technician',
        location: 'Windhoek',
        salary: '',
        jobType: 'Full-time',
        closingDate: '18 March 2024',
        short: "IT Technician with experience in maintaining and troubleshooting hardware and software systems. The ideal candidate should have strong technical skills, as well as experience with network administration and security protocols.",
        jobDescription: "We are seeking a highly motivated and detail-oriented IT Technician to join our customer experience company. As an IT Technician, you will be responsible for providing technical support to our clients and ensuring that our hardware and software systems are functioning effectively and efficiently.",
        responsibilities: [
            "Perform fieldwork and hardware deployments at customer sites across the country",
            "Install, configure, and maintain computer hardware, software, and networking equipment and at customer sites",
            "Conduct site assessments to determine hardware and infrastructure requirements for new installations",
            "Provide on-site technical support to clients during hardware installations and deployments",
            "Do daily, weekly and periodic checks to all hardware and software deployed on client sites. Perform system upgrades, updates and backups",
            "Work closely with the project manager to ensure successful completion of hardware deployments and installations on time and within budget.",
            "Document hardware installation processes and procedures for future reference.",
            "Provide technical and product training to clients on hardware and software.",
            "Troubleshoot technical issues and provide solutions in a timely manner.",
            "Maintain and update hardware inventory and documentation for field deployments ",
            "Maintain and update inventory of all equipment.",
            "Monitor and resolve security threats to the network and IT systems.",
            "Stay current with industry trends and advancements in hardware technology to ensure the best possible customer experience.",
            "Software products testing and error reporting"
        ],
        requirements: [
            "Bachelor’s Degree in Computer Science or related field",
            "1-2 years’ experience in computer networks and systems maintenance",
            "Strong knowledge of Windows and Linux operating systems",
            "Excellent diagnostic and problem-solving skills",
            "IT Certifications (A+, N+) will be an advantage.",
            "Ability to work independently and in a team environment",
            "Code B Driving Licence + 2 years and longer",
        ],
        Preferred: [],
        conclusion: "This is a full-time position with a competitive salary and benefits package. If you are a driven and detail-oriented individual with a passion for technology, we encourage you to apply. Our company is dedicated to providing the best customer experience and we are looking for an IT Technician who shares our commitment.",
    },
]
   
render() {
    const listing = this.listings[this.state.listingIndex];

    return (
        <div>
        <div className="contentBox pages careers clearfix">
            <div className="col-md-5">
                <div className="submissions">
                    Email your CV and cover letter to <strong><Mailto
                        email='careers@averly.com.na'
                        headers={
                            {subject:'Vacancies'}
                        }
                    /></strong>
                    <br />
                    Const subject = jobTitle; // that's it
                </div>
                <div className="careersCode">
                    <pre><code>
                        <span className="comment">// This is a comment</span><br />
                        <span className="keyword">if</span> (visitor === <span className="string">'Human'</span>) &#123;<br />
                        console.log(<span className="string">'Hello, Human!'</span>);<br />
                        &#125; <span className="keyword">else</span> &#123;<br />
                        console.log(<span className="string">'Hello, Bot!'</span>);<br />
                        &#125;
                    </code></pre>
                </div>
                {
                    this.listings.map( (a, index) => 
                        <div className="careerBox" key={index} onClick = { () => this.setState({ listingIndex: index }) }>
                            <div className="jobTitle">{a.jobTitle}</div>
                            <div className="location">{a.location} - {a.jobType}</div>
                            <div className="location">Closing Date: {a.closingDate}</div>
                            <div className="short">{a.short}</div>
                        </div>
                    )
                }
            </div>
            <div className="col-md-7">
                <div className="careerDescriptionBox">
                <div className="jobTitle">{listing.jobTitle}</div>
                        <div className="location">{listing.location}</div>
                        <div className="jobType">{listing.jobType}</div>
                        <div className="jobType">Closing Date: {listing.closingDate}</div>
                        <div className="jobDescription">{listing.jobDescription}</div>
                        {
                            listing.responsibilities.length
                                ?   <div className="specs">
                                        <h2>Responsibilities</h2>
                                        <ul>
                                        {
                                            listing.responsibilities.map( a => 
                                                <li>{ a }</li>
                                            )
                                        }
                                        </ul>
                                    </div>
                                :   null
                        }
                        {
                            listing.requirements.length
                                ?   <div className="specs">
                                        <h2>Requirements</h2>
                                        <ul>
                                        {
                                            listing.requirements.map( a => 
                                                <li>{ a }</li>
                                            )
                                        }
                                        </ul>
                                    </div>
                                :   null
                        }
                        {
                            listing.Preferred.length
                                ?   <div className="specs">
                                        <h2>Preferred</h2>
                                        <ul>
                                        {
                                            listing.Preferred.map( a => 
                                                <li>{ a }</li>
                                            )
                                        }
                                        </ul>
                                    </div>
                                :   null
                        }


                    <div className="conclusion">{ listing.conclusion }</div>
                    
                </div>
            </div>
        </div>

        <div className="rectSpace last">
            <div className="contentBox pages clearfix">
                <div className="col-md-3" style={{paddingLeft: 0}}><h2>Industries</h2></div>
                <div className="col-md-9 rectSpaceBlock dark">
                    <ul>
                    <li>Banking</li>
                    <li>Telecom</li>
                    <li>Healthcare</li>
                    <li>Government</li>
                    <li>Retail</li>
                    <li>Education</li>
                    <li>Mining</li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
      );
   }

}
