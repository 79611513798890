import React from "react";
import {Helmet} from "react-helmet"
import Mailto from 'react-protected-mailto'
import Textarea from 'react-textarea-autosize'
//COMPONENTS     ///////////////////////////////////////////////////////////////////
import  Header from "../components/layout/Header";
import  Footer from "../components/layout/Footer";
//import  Maps from "../components/layout/Maps";
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import city from '../assets/images/contact/city.jpg'
import close from '../assets/images/contact/close.png'
import '../assets/css/Contact.css';
///////////////////////////////////////////////////////////////////////////////////

import ReactGA from 'react-ga';
ReactGA.initialize('UA-127398027-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class Contact extends React.Component {
	constructor(){
      super()
      this.openForm = this.openForm.bind(this)
      this.submitForm = this.submitForm.bind(this)
      this.state = {
				meta: {
					title: "Averly | Contact us",
					description: "Feel free to contact us with any questions or sales inquiries.",
					canonical: "https://averly.com.na/contact/",
				},
				visibility: false,
				posted: false,
				name: null,
				email: null,
				phone: null,
				company: null,
				message: null,
      }
   }

   componentWillMount(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
   }

   //add form input binds to state below

   openForm(){
      this.setState({visibility: !this.state.visibility})
      document.body.scrollTop = document.documentElement.scrollTop = 40;
   }

   closeForm(){
      this.setState({visibility: false})
      document.body.scrollTop = document.documentElement.scrollTop = 0;
   }

   submitForm(){
      //implement form check
      var error = false

      if(error){

      }

      else {
         const close = document.getElementById("closeCountDown");
         document.body.scrollTop = document.documentElement.scrollTop = 40;
         this.setState({posted: true})
         let count = 8;
         var id = setInterval(() => {
            count--;
            close.innerHTML = count;
            if(count === 0){ 
               this.setState({visibility: false});
               this.setState({posted: false});
               clearInterval(id);
            }
         }, 1000);
      }
   }

   render() {

      const formVisibility = this.state.visibility ? {display: "block"} : {display: "none"}
      const contentVisibility = this.state.visibility ? {display: "none"} : {display: "block"}

      //form styles
      const formVisibility2 = this.state.posted ? {display: "none"} : {display: "block"}
      const posted = this.state.posted ? {display: "block"} : {display: "none"}


      return (
				<div>
					<Helmet>
 					 <title>{this.state.meta.title}</title>
 					 <meta name="description" content={this.state.meta.description} />
 					 <link rel="canonical" href={this.state.meta.canonical} />
 				 </Helmet>
            <Header />
            <div className="contact">
               <section style={{marginTop: "107px"}}>
                  <div className="container" style={contentVisibility}>
                     <div className="city"><img src={city} alt="Windhoek church" className="responsive" width="100%" /></div>
                     <div className="writeUs">Hey, pop us a mail or drop by and have some great coffee with us.</div>
                     <div className="spacer"></div>
                     <p className="contactDetails">
                     Office: +264 61 247 669<br />
                     Sales: +26481 6767 402<br />
                     Support: +2681 333 1160<br />
                     Email: <Mailto
                           email='hello@averly.com.na'
                           headers={
                             {subject:'Question from the website'}
                           }
                        />
                     <br /><br />
                     Location: <br />
                     No 148,<br />
                     C/O Jan Jonker Road &amp; Newton Str.
                     </p>
                     {/* <div className="submitButton" onClick={()=>this.openForm(this)}>Send Message</div> */}
                  </div>
                  {/* <div className="container" style={formVisibility}>
                     <div className="formContainer">

                        <div style={formVisibility2}>
                           <div className="closeBtn"  onClick={()=>this.closeForm()}><img src={close} alt="close modal" /></div>
                           <div className="form-group">
                              <h3>Dear Averly,</h3>
                              <Textarea className="form-control" placeholder="Start typing..." />
                           </div>
                           <div className="form-group">
                              <h3>With hugs,</h3>
                              <input type="text" className="form-control" placeholder="Your Name" />
                              <div className="spacer"></div>
                              <input type="email" name="email" className="form-control" placeholder="Email" />
                              <div className="spacer"></div>
                              <input type="text" className="form-control" placeholder="Phone" />
                              <div className="spacer"></div>
                              <input type="text" className="form-control" placeholder="Company (Optional)" />
                              <div className="spacer"></div>
                           </div>
                           <div className="submitButton" onClick={()=>this.submitForm()}>Send Message</div>
                        </div>
                        <div style={posted}>
                           <div className="closeBtn" id="closeCountDown"><img src={close} alt="close modal" /></div>
                           <h3 style={{marginTop:"50px",textAlign:"center"}}>Message was successfully sent! Our team will get back to you shortly.</h3>
                        </div>
                     </div>
                  </div> */}
               </section>
            </div>
            <Footer />
         </div>

      );
   }

}
