import React from "react";
import { NavLink } from "react-router-dom";

//COMPONENTS     ///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class SoftwareDevelopment extends React.Component {

   render() {

      return (
         <div>

         	<div className="contentBox pages">
               <h1>Software Development & Design</h1>
               <p className="last">Averly is a full-cycle software development company and we understand that product development is to design products that improve the lives of clients, employees and customers. We develop cloud-native applications that leverage cloud environments and provide improved performance, authentication, security, redundancy, scalability and code deployment.</p>

               <h2>Development Process</h2>
               <ul className="last">
                  <li>We typically work in two-week development sprints, side by side with our clients.</li>
                  <li>Morning 10 minute sprint meetings to discuss the day ahead.</li>
                  <li>Short development cycles to allow real-time identification and adjustments of issues.</li>
                  <li>Prioritized client feedback.</li>
                  <li>Double diamond design approach. Discover/ define and develop/ deliver.</li>
               </ul>

               <h2>Build Features</h2>
               <ul>
                  <li>Pixel perfect &amp; visually appealing designs that serve purpose.</li>
                  <li>Light weight, fully responsive, asynchronous &amp; fast loading production code.</li>
                  <li><strong>No internet?</strong> No problem. Our sites are built for offline browsing. Go ahead and disable your internet and reload the page. We'll still be here.</li>
                  <li>Realtime, redundant, scalable and secure cloud-native applications.</li>
               </ul>
            </div>

            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Services</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul>
                        <li>Product Discovery &amp; Design Sprints</li>
                        <li>API design and development</li>
                        <li>Migration and replatforming</li>
                        <li>Native app development</li>
                        <li>SaaS development </li>
                        <li>UX/UI/CX design</li>
                        <li>Front-end and backend Software Engineering</li>
                        <li>Business Analytics / Data Visualization / Predictive Analytics</li>
                     </ul>
                  </div>
               </div>
            </div>

            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Cloud</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul>
                        <li>AWS / Google Cloud Platform</li>
                        <li>EC2 / Compute Engine / Beanstalk</li>
                        <li>RDS / Cloud SQL / Realtime Database</li>
                        <li>Lambda / Cloud Functions</li>
                        <li>Cognito / Firebase Authentication</li>
                        <li>Agolia</li>
                        <li>Docker / Kubernetes </li>
                        <li>QuickSight / Data Studio</li>
                     </ul>
                  </div>
               </div>
            </div>

            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Technology</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul>
                        <li>Postman / Swagger / GraphQL </li>
                        <li>Travis / Circle CI </li>
                        <li>React / Redux / MobX</li>
                        <li>React Native / iOS / Android </li>
                        <li>Javascript / Node / Electron / Phoenix</li>
                        <li>Surge / Heroku</li>
                        <li>Github / CodeCommit / NPM</li>
                     </ul>
                  </div>
               </div>
            </div>


            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Industries</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul>
                        <li>Banking</li>
                        <li>Telecom</li>
                        <li>Healthcare</li>
                        <li>Government</li>
                        <li>Retail</li>
                        <li>Education</li>
                        <li>Mining</li>
                        <li>Fashion</li>
                        <li>Real Estate</li>
                        <li>Legal</li>
                        <li>Non Profit</li>
                     </ul>
                  </div>
               </div>
            </div>





         </div>
      );
   }

}
