import React from "react";

//COMPONENTS     ///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import cover from '../../assets/images/home/cover.jpg'
///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class Slider extends React.Component {

   render() {

      return (
         <div className="hero-area-section">
            <div className="container">
               <div className="bxdd-sec">
                  <div className="row">
                     <div className="hero-area-bg-sec">
                        <div className="textContainer">
                           <div className="hero-area-text">
                              <h2>Reimagining companies to improve quality of customer service</h2>
                           </div>
                        </div>

                        <div className="pr-sec-img">
                           <img src={cover} alt="Averly company reception drawing" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }

}
