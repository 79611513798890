import React from "react";

//COMPONENTS     ///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../assets/css/Layout.css';
import '../assets/css/Responsive.css';
///////////////////////////////////////////////////////////////////////////////////

export default class Layout extends React.Component {
	
   render() {

      return (
         <div id="siteDocument">
            {this.props.children}
         </div>
      );
   }

}
