import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'

import Contact from './pages/Contact'
import Home from './pages/Home'
import Layout from './pages/Layout'
import DigitalSignage from './pages/DigitalSignage'
import QueueManagement from './pages/QueueManagement'
import SelfServiceKiosks from './pages/SelfServiceKiosks'
import SoftwareDevelopment from './pages/SoftwareDevelopment'
import Careers from './pages/Careers'

const DefaultRouter = () => (
  <Router>

    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/contact/" component={Contact} />
        <Route path="/digital-signage/" component={DigitalSignage} />
        <Route path="/queue-management/" component={QueueManagement} />
        <Route path="/self-service-kiosks/" component={SelfServiceKiosks} />
        <Route path="/software-development/" component={SoftwareDevelopment} />
        <Route path="/careers/" component={Careers} />
        <Route path='/sign-in/' component={() => window.location = 'https://console.averly.com.na'}/>
        <Route path='/twitter/' component={() => window.location = 'https://twitter.com/averlydigital'}/>
        <Route path='/facebook/' component={() => window.location = 'https://www.facebook.com/Averly-Digital-Solutions-308707046182482/'}/>
        <Route component={Home} />
      </Switch>
    </Layout>

  </Router>
)
export default DefaultRouter
