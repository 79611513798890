import React from "react";
import { NavLink } from "react-router-dom";

//COMPONENTS     ///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class Socials extends React.Component {
	
   render() {

      return (
         <div></div>
      );

   }

}


// <div className="footer-top-rea">
//             <div className="container">
//                <div className="bxdd-sec">
//                   <div className="row">
//                      <div className="col-md-12">
//                         <div className="footer-social-area">
//                            <h3>Stay connected </h3>
//                            <div className="social-link">
//                               <NavLink to="/twitter" target="_blank"><i className="fab fa-twitter"></i></NavLink>
//                               <NavLink to="/facebook" target="_blank"><i className="fab fa-facebook-f"></i></NavLink>
//                               <NavLink to="/"><i className="fab fa-instagram"></i></NavLink>
                              
//                            </div>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//          </div>