import React from "react"
import {Helmet} from "react-helmet"
//COMPONENTS     ///////////////////////////////////////////////////////////////////
import  Header from "../components/layout/Header";
import  Content from "../components/layout/Content";
import  Cover from "../components/layout/Cover";
import  Footer from "../components/layout/Footer";
import  Products from "../components/layout/Products";
import  Socials from "../components/layout/Socials";
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import cover from '../assets/images/self-service-kiosks/cover.jpg'
import '../assets/css/Pages.css';
///////////////////////////////////////////////////////////////////////////////////

import ReactGA from 'react-ga';
ReactGA.initialize('UA-127398027-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class SelfServiceKiosks extends React.Component {
	constructor(){
      super()
      this.state = {
				meta: {
					 title: "Averly | Self service kiosks",
					 description: "We develop and supply self service kiosks in Namibia. Expand your office hours and reach by deploying self service kiosks.",
					 canonical: "https://averly.com.na/self-service-kiosks/",
				},
				coverImage: cover,
				coverTitle: "24/7 Service with reduced wait times and operational costs.",
				page: "SelfServiceKiosks",
				getStarted: true,
      }
   }

   componentWillMount(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
   }

   render() {
      return (
				<div>
					<Helmet>
						<title>{this.state.meta.title}</title>
	 					<meta name="description" content={this.state.meta.description} />
	 					<link rel="canonical" href={this.state.meta.canonical} />
					</Helmet>
					<Header />
					<Cover data={this.state} alt="Woman using a smartphone and a laptop" />
					<Content page={this.state.page} />
					<Products />
					<Socials />
					<Footer />
				</div>
      );
   }

}
