import React from "react";
import { NavLink } from "react-router-dom";

//COMPONENTS     ///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import logo from '../../assets/images/layout/logo-footer.png'
import '../../assets/css/Footer.css';
///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class Footer extends React.Component {

   render() {

      return (
         <div className="footer-area">
            <div className="container">
               <div className="footer-bg-color">
                  <div className="clearfix">
                     <div className="col-ave-company">
                        <div className="footer-single-blk">
                           <div className="footer-logo">
                              <NavLink to="/"><img src={logo} alt="company logo" /></NavLink>
                           </div>
                           <div className="footer-content">
                              <p>A complex system that works is invariably found to have evolved from a simple system that worked <br /><br />- John Gall</p>
                           </div>
                        </div>
                     </div>

                     <div className="col-ave-products">
                        <div className="footer-single-blk">
                           <div className="footer-single-blk-title">
                              <h3>PRODUCTS</h3>
                           </div>
                           <div className="footer-content">
                              <div className="footer-list">
																<ul>
                                 <li><NavLink to="/self-service-kiosks/">Self Service</NavLink></li>
                                 <li><NavLink to="/queue-management/">Queue Management</NavLink></li>
                                 <li><NavLink to="/digital-signage/">Digital Signage</NavLink></li>
                                 <li><NavLink to="/software-development/">Software Development</NavLink></li>
																 </ul>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-ave-about">
                        <div className="footer-single-blk">
                           <div className="footer-single-blk-title">
                              <h3>ABOUT</h3>
                           </div>
                           <div className="footer-content">
                              <div className="footer-list">
																<ul>
                                 <li><NavLink to="/contact/">Contact us</NavLink></li>
                                 <li><NavLink to="/sign-in/" target="_blank">Sign in</NavLink></li>
																</ul>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>

                  <div className="spacer"></div>

                  <div className="footer-bottom">
                     <p>© {new Date().getFullYear()} Averly . All Rights Reserved</p>
                  </div>

               </div>
            </div>
         </div>
      );
   }
}
