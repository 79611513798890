import React from "react";
import { NavLink } from "react-router-dom";

export default class QueueManagement extends React.Component {

   render() {
      return (
         <div>
         	<div className="contentBox pages">
               <h1>Queue Management with Averly</h1>
               <p>Ticketing system for virtual queues and management of "first come, first serve" linear queues.</p>
               <p className="last">aQueue eliminates physical queues, clutter from waiting areas, the mystery and worry from the wait by creating virtual queues, guiding and continuously updating clients  about where to go, where to wait and waiting times. This also aids in avoiding being in the wrong areas waiting and wasting time from the moment client arrives until serviced resulting in ease of control.</p>

               <h2>Skill based Routing</h2>
               <p>Having to wait for a customer to approach the counter only to tell them that they have been waiting in the wrong line is likely to result in client frustration. aQueue eliminates confusion and reduces the wasted time between transactions by using what we call skill-based routing.</p>
               <p>What skill-based routing does is, it directs customers to the right service, waiting room, office or a counter as soon as they print their ticket. Additionally clients can give a brief description of their issue or query and the system will automatically point the customer to the right service section.</p>
               <p className="last">aQueue also handles referrals routing, in cases where clients choose a wrong service, they will automatically be redirected to the right counter with some level of priority to avoid wasting more time.</p>

               <h2>Enhancing performance</h2>
               <p>aQueue automatically captures data at every step in the process. Managers can use such data to determine and analyze customer experience, waiting time per customer and cost per transaction.</p>
               <p>This way you are able to initiate the right operational improvement activities, based on the data and knowledge offered by the system, to improve efficiency, shorten waiting time and reduce costs. With real time data, managers can take immediate action to use staff more efficiently and reduce delays. Staff performance and skill levels can also be evaluated to facilitate training as well as growth within the organization ladders.</p>
               <p className="last">aQueue provides effortless efficiency, creating a relaxed welcoming environment within the organization. With all the buzz and limited time within a work day, no one enjoys spending endless hours waiting to be serviced. Less time spent waiting, equals more returning customers.</p>
            </div>

            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Benefits of our queue management system</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul>
                        <li>Customers are served quickly for improved efficiency and satisfaction.</li>
                        <li>Afford the customer comfort of sitting and not stand in long lines.</li>
                        <li>Audio-visual display and announcements system for efficient queue management.</li>
                        <li>Keeps track and forecasts the flow of customers.</li>
                        <li>Your staff are confident and in control.</li>
                        <li>Easy installation and simple click operation.</li>
                        <li>Obtain statistics on wait times, transactions and number of customers served.</li>
                        <li>Generation of daily and monthly statistical reports to facilitate top management's decision making process. </li>
                     </ul>
                  </div>
               </div>
            </div>

            <div className="contentBox pages">
               <p>The system provides both visual and audible direction to the customer, featuring a main display and a position indicator module. The positions are managed by staff from a terminal desktop application installed on each terminal or a tablet device. aQueue is flexible, providing you with multiple options for display and announcements.</p>
               <p>A standard aQueue Solutions comprises of a Kiosk equipped with a receipt printer, an LED Screen/s for display, an operator terminal application plus the audio pieces and queuing software connecting all the hardware together.</p>
            </div>

            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Industries</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul>
                        <li>Banking</li>
                        <li>Telecom</li>
                        <li>Healthcare</li>
                        <li>Government</li>
                     </ul>
                  </div>
               </div>
            </div>

         </div>
      );
   }

}
