import React from "react"
import {Helmet} from "react-helmet"
//COMPONENTS     ///////////////////////////////////////////////////////////////////
import  Header from "../components/layout/Header";
import  Content from "../components/layout/Content";
import  Cover from "../components/layout/Cover";
import  Footer from "../components/layout/Footer";
import  Products from "../components/layout/Products";
import  Socials from "../components/layout/Socials";
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import cover from '../assets/images/software-development/cover.jpg'
import '../assets/css/Pages.css';
///////////////////////////////////////////////////////////////////////////////////

import ReactGA from 'react-ga';
ReactGA.initialize('UA-127398027-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class SoftwareDevelopment extends React.Component {
	constructor(){
      super()
      this.state = {
         meta: {
            title: "Averly | Software, Web Development & Design Company",
            description: "Averly is a custom software and web development company based in Namibia. We develop cloud-native applications for  improved security, redundancy and scalability.",
            canonical: "https://averly.com.na/software-development/",
         },
         coverImage: cover,
         coverTitle: "Bespoke software, mobile and web development.",
         page: "SoftwareDevelopment",
         getStarted: true,
      }
   }

   componentWillMount(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;

   }

   render() {

      return (
				<div>
					<Helmet>
 					 <title>{this.state.meta.title}</title>
 					 <meta name="description" content={this.state.meta.description} />
 					 <link rel="canonical" href={this.state.meta.canonical} />
 				 </Helmet>
				<Header />
				<Cover data={this.state} alt="Laptop and smartphone on a desk" />
				<Content page={this.state.page} />
				<Products />
				<Socials />
				<Footer />
      </div>
      );
   }

}
