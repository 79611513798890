import React from "react";
import { NavLink } from "react-router-dom";
var HtmlTotReact = require('html-to-react').Parser;

export default class Cover extends React.Component {
	constructor(props){
      super(props)
      this.state = {
         coverImage: props.data.coverImage,
         coverTitle: props.data.coverTitle,
         getStarted: props.data.getStarted,
				 alt: props.alt,
      }
   }

   render() {
      var htmlTotReact = new HtmlTotReact();
      var reactElement = htmlTotReact.parse(this.state.coverTitle);
      const getStarted = this.state.getStarted ? <NavLink to="/contact/" className="getStartedBtn">Get Started</NavLink> : null
      return (
      	<section className="cover">
      		<div className="container">
               <img src={this.state.coverImage} alt={this.state.alt} />
               <div className="coverTitle">
                  {reactElement}
                  {getStarted}
               </div>
	         </div>
         </section>
      );
   }

}
