import React from "react";
import { NavLink } from "react-router-dom";

//COMPONENTS     ///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import logo from '../../../assets/images/layout/logo.png'
///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class Header extends React.Component {
	constructor(){
      super()
      this.toggleMobileMenuVisibility = this.toggleMobileMenuVisibility.bind(this)
      this.state = {
         primaryNavigation: [
            {title: "Self Service", to: "/self-service-kiosks/"},
            {title: "Queue Management", to: "/queue-management/"},
            {title: "Digital Signage", to: "/digital-signage/"},
            {title: "Software Development", to: "/software-development/"},
         ],
         secondaryNavigation: [
            {title: "Home", to: "/", target: ""},
            {title: "Careers", to: "/careers/", target: ""},
            {title: "Contact", to: "/contact/", target: ""},
            {title: "Sign in", to: "/sign-in", target: "_blank"},
         ],
         mobileMenuVisibility: false
      }
   }

   toggleMobileMenuVisibility(status){
      const nav = document.getElementById("navigationContainer")
      if(status){
         const visibility = !this.state.mobileMenuVisibility
         this.setState({mobileMenuVisibility: visibility}, () => {
            if(visibility){nav.className += ' menuOpen';}
            else {nav.classList.remove('menuOpen')}
         })
      }
      else {
         this.setState({mobileMenuVisibility: false}, () => {
            nav.classList.remove('menuOpen')
         })
      }
   }

   render() {
      const visibility = this.state.mobileMenuVisibility
      const secondaryStyle = visibility ? {display:"none"} : {display:"block"}

      return (
         <div id="navigationContainer">
            <div className="header-logo">
               <NavLink to="/"><img src={logo} alt="company logo" /></NavLink>
            </div>
            <div className="mobileMenuOpen" onClick={() => this.toggleMobileMenuVisibility(true)}>
               <div className="dash"></div>
               <div className="dash"></div>
               <div className="dash"></div>
            </div>
            <div className="navigation">
               <div className="header-area-right-menu">
                  <div className="secondaryMenu" style={secondaryStyle}>
                     <nav id="menu-active">
                        <ul>
                           {
                              this.state.secondaryNavigation.map((data, index)=>
                                 <li key={index} onClick={() => this.toggleMobileMenuVisibility(false)}><NavLink to={data.to} target={data.target} activeClassName="active">{data.title}</NavLink></li>
                              )
                           }
                        </ul>
                     </nav>
                  </div>
                  <div className="primaryMenu">
                     <ul>
                        {
                           this.state.primaryNavigation.map((data, index)=>
                              <li key={index} onClick={() => this.toggleMobileMenuVisibility(false)}><NavLink  to={data.to} activeClassName="active">{data.title}</NavLink></li>
                           )
                        }
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      );
   }

}
