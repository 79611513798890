import React from "react";

//COMPONENTS     ///////////////////////////////////////////////////////////////////
import Home from './content/Home'
import DigitalSignage from './content/DigitalSignage'
import QueueManagement from './content/QueueManagement'
import SelfServiceKiosks from './content/SelfServiceKiosks'
import SoftwareDevelopment from './content/SoftwareDevelopment'
import Careers from './content/Careers'
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class Content extends React.Component {
	constructor(props){
      super(props)
      this.state = {
         page: props.page,
      }
   }

   components = {
      Home: Home,
      DigitalSignage: DigitalSignage,
      QueueManagement: QueueManagement,
      SelfServiceKiosks: SelfServiceKiosks,
      SoftwareDevelopment: SoftwareDevelopment,
      Careers: Careers,
   }

   render() {
      const Content = this.components[this.state.page]
      return (
      	<section>
            <Content />
         </section>
      );
   }

}
