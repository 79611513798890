import React from "react"
import {Helmet} from "react-helmet"

//COMPONENTS     ///////////////////////////////////////////////////////////////////
import  Header from "../components/layout/Header";
import  Content from "../components/layout/Content";
import  Cover from "../components/layout/Cover";
import  Footer from "../components/layout/Footer";
import  Products from "../components/layout/Products";
import  Socials from "../components/layout/Socials";
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import cover from '../assets/images/digital-signage/cover.jpg'
import '../assets/css/Pages.css';
///////////////////////////////////////////////////////////////////////////////////

import ReactGA from 'react-ga';
ReactGA.initialize('UA-127398027-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class DigitalSignage extends React.Component {
	constructor(){
      super()
      this.state = {
				meta: {
					 title: "Averly | Digital Signage Suite",
					 description: "Averly is a leader in digital signage in Namibia. We provide managed digital indoor and outdoor displays. Engage customers and reduce perceived wait times.",
					 canonical: "https://averly.com.na/digital-signage/",
				},
				coverImage: cover,
				coverTitle: "Engage customers and reduce perceived wait times.",
				page: "DigitalSignage",
				getStarted: true,
      }
   }

   componentWillMount(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
   }

   render() {
      return (
				<div>
					<Helmet>
						<title>{this.state.meta.title}</title>
	 					<meta name="description" content={this.state.meta.description} />
	 					<link rel="canonical" href={this.state.meta.canonical} />
					</Helmet>
					<Header />
					<Cover data={this.state} alt="Digital outdoor signage" />
					<Content page={this.state.page} />
					<Products />
					<Socials />
					<Footer />
         </div>
      );
   }
}
