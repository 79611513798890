import React from "react";
import { NavLink } from "react-router-dom";

//COMPONENTS     ///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class DigitalSignage extends React.Component {

   render() {

      return (
         <div>
         	<div className="contentBox pages">
               <h1>Digital signage and indoor content display</h1>
               <p className="last">Averly Digital Signage (ADS) is a network of managed digital displays designed to drive awareness and engagement within predetermined objectives. It is both the platform and the content on which the telecommunication activities of the system reside. The full solution comprises of a full HD display connected to a specialized media box running dedicated software to present and broadcast the content.</p>
               <h2>Why Choose Averly Digital Signage?</h2>
               <p>Having the right partner (service provider) onboard impacts your outlook on service provision and helps cut expenditure. We have taken time and over the years developed software solutions for our digital signage, which not only give us the platform to control the network but also the ability to incorporate various channels seamlessly and distribute the content.</p>
               <p>ADS as passionately known allows you to reach more audiences realtime increasing your chances of reaching target audience by replacing the old fashioned static poster service.</p>
               <p>ADS helps eliminate stress and frustration in areas were commonly queueing or a certain wait period is required by information and entertaining clients while waiting to be attended.</p>
               <p>ADS guarantees 24/7 uninterrupted content management and scheduling which can also include weather forecasts, video, news, current affairs, sports updates, events, emergency broadcasts, html and audio media content.</p>
               <p>By allowing access to update content remotely to our clients we create an avenue that allows them to engage their clients in a redefined form, showing their clients that they are evolving with times by adapting to technological trends and staying relevant in the ever competitive corporate world.</p>
               <p>Clients in all sectors are always eager to see how their service providers are bettering their services and how it not only impacts them but the environment as well. ADS moves away from the static poster marketing/ communication to online digital displays.</p>
               <p>ADS is part of our efforts in minimizing carbon footprint and playing part in a global call of conservation by closing the communication gap between service providers and their clients.</p>
            </div>

            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Industries</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul>
                        <li>Banking</li>
                        <li>Telecom</li>
                        <li>Healthcare</li>
                        <li>Government</li>
                        <li>Retail</li>
                        <li>Education</li>
                        <li>Mining</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      );
   }

}
