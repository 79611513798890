import React from "react";
import { NavLink } from "react-router-dom";

//COMPONENTS     ///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class SelfServiceKiosks extends React.Component {

   render() {

      return (
         <div>
         	<div className="contentBox pages">
               <h1>Self Service Kiosks</h1>
               <p>Averly Self-service Kiosk (ASK) gives you the freedom to engage in the emerging technology while reducing operational cost. The product enables you to empower your clients to avoid queueing for services they can have resolved with a click of few buttons. With the presence of the kiosk service volumes flow more efficiently and employees have more time to focus on clients that require their expertise.</p>
               <p>Your environment works more efficiently, the transition from online to onsite is seamless, and everybody knows what to do without confusion.</p>
               <p className="last">Designed and manufactured emphasizing on customer experience at the core, the kiosks feature a contemporary design and offer innovative self-service options, while transforming customer experiences.</p>

               <h2>Why Choose Averly self-service kiosk?</h2>
               <p><strong>Reduce wait time</strong> - give your customers an express service option to avoid queueing and get instant service.</p>
               <p><strong>Reduce Operational cost</strong> - gives you the ability to serve more customers daily than it would be with only staff.</p>
               <p><strong>Customer satisfaction and trust</strong> - convenient service with instant reference in the form of receipt or notification on your mobile device, creating reassurance that customer query is being attended.</p>
               <p><strong>24/7 Service</strong> - the kiosk gives you freedom to be able to operate at any given time with technically no staff member required to be on duty.</p>
               <p><strong>Customizable</strong> - the hardware and software are custom made to the operational environment as some stations are exposed to harsh elements of nature</p>
            </div>

            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Self-Service Kiosk modules</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul className="threeCol">
                        <li>Touchscreen</li>
                        <li>Document Scanner</li>
                        <li>ID Scanner</li>
                        <li>Fingerprint Scanner</li>
                        <li>Audio Feedback</li>
                        <li>Virtual Assistant</li>
                        <li>Camera</li>
                        <li>Build-in Wifi / LAN</li>
                        <li>Cash Deposit</li>
                        <li>Signature Pad</li>
                        <li>Receipt Printer</li>
                        <li>A4 Document Printing</li>
                        <li>Card Printing</li>
                        <li>Key lock security</li>
                        <li>Low power consumption</li>
                     </ul>
                  </div>
               </div>
            </div>

            <div className="rectSpace last">
               <div className="contentBox pages clearfix">
                  <div className="col-md-3" style={{paddingLeft: 0}}><h2>Industries</h2></div>
                  <div className="col-md-9 rectSpaceBlock dark">
                     <ul>
                        <li>Banking</li>
                        <li>Telecom</li>
                        <li>Healthcare</li>
                        <li>Government</li>
                        <li>Retail</li>
                        <li>Education</li>
												<li>Logistics</li>
                        <li>Mining</li>
                     </ul>
                  </div>
               </div>
            </div>

            <div className="contentBox pages">
               <div style={{textAlign: "center"}}>
                  <NavLink to="/contact/" className="get-stred-btn ">Get Started <i className="fa fa-angle-right"></i></NavLink>
               </div>
            </div>
         </div>
      );
   }

}
