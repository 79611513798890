import React from "react";
import { NavLink } from "react-router-dom";

//COMPONENTS     ///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////


//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../assets/css/Products.css';
import signage from '../../assets/images/layout/signage.png'
import kiosk from '../../assets/images/layout/kiosk.png'
///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class Products extends React.Component {

   render() {

      return (
         <div className="item-thmb-area products">
            <div className="container">
               <p>Ready to get started?</p>
               <h1 className="textCenter">Our Products.</h1>
               <div className="row darkShadow">
                  <div className="col-md-4 col-sm-4 npdng">
                     <NavLink to="/digital-signage/" className="sngle-tm-blk">
                        <div className="tm-left-text">
                           <h4>Digital Signage</h4>
                           <ul>
                              <li>Manage</li>
                              <li>Distribute</li>
                              <li>Seamlessly</li>
                           </ul>
                        </div>

                        <div className="tm-right-img">
                           <img src={signage} alt="digital signage display" />
                        </div>
                     </NavLink>
                  </div>
                  <div className="col-md-4 col-sm-4 npdng">
                     <NavLink to="/queue-management/" className="sngle-tm-blk green">
                        <div className="tm-left-text">
                           <h4>Queue Management</h4>
                           <ul>
                              <li>Organize</li>
                              <li>Measure</li>
                              <li>Improve</li>
                           </ul>
                        </div>

                        <div className="tm-right-img kiosk">
                           <img src={kiosk} alt="queue management kiosk" />
                        </div>
                     </NavLink>
                  </div>
                  <div className="col-md-4 col-sm-4 npdng">
                     <NavLink to="/self-service-kiosks/" className="sngle-tm-blk yellow">
                        <div className="tm-left-text">
                           <h4>Self Service Kiosks</h4>
                           <ul>
                              <li>Service</li>
                              <li>Monitor</li>
                              <li>Remote</li>
                           </ul>
                        </div>

                        <div className="tm-right-img kiosk">
                           <img src={kiosk} alt="self service kiosk" />
                        </div>
                     </NavLink>
                  </div>
               </div>
            </div>
         </div>
      );
   }

}
