import React from "react";
import { NavLink } from "react-router-dom";

//COMPONENTS     ///////////////////////////////////////////////////////////////////
import Navigation from './header/Navigation'
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import logo from '../../assets/images/layout/logo.png'
import '../../assets/css/Header.css';
///////////////////////////////////////////////////////////////////////////////////

//Actions -> Stores | flux


export default class Header extends React.Component {
   constructor(){
      super()
      this.state = {
         shadowVisibility: false
      }
   }

	componentWillMount(){
      var shadowVisibility=this.state.shadowVisibility

      //window.addEventListener('scroll', this.updateScrollHeader, false);

   }

   updateScrollHeader(){
      var scroll = this.scrollY
      console.log(scroll)
      console.log(this.state.shadowVisibility)
      // if(scroll>40){
      //    if(!this.state.shadowVisibility){
      //       this.setState({shadowVisibility: true})
      //    }
      // }
      // else {
      //    if(this.state.shadowVisibility){
      //       this.setState({shadowVisibility: false})
      //    }
      // }
   }

   render() {
      const style= this.state.shadowVisibility ? {boxShadow:"0 5px 8px -4px #00000042"} : {boxShadow:"none"}
      return (
         <header>
            <div className="container">
               <div className="header-container">
                  <div className="header-logo">
                     <NavLink  to="/"><img src={logo} alt="company logo" /></NavLink>
                  </div>
                  <Navigation />
               </div>
            </div>
         </header>
      );
   }

}
