import React from "react"
import {Helmet} from "react-helmet"

//COMPONENTS     ///////////////////////////////////////////////////////////////////
import  Content from "../components/layout/Content"
import  Cover from "../components/layout/Cover"
import  Footer from "../components/layout/Footer"
import  Header from "../components/layout/Header"
import  Products from "../components/layout/Products"
import  Slider from "../components/layout/Slider"
import  Socials from "../components/layout/Socials"
///////////////////////////////////////////////////////////////////////////////////

//ASSETS     //////////////////////////////////////////////////////////////////////
import cover from '../assets/images/home/cover.jpg'
///////////////////////////////////////////////////////////////////////////////////

import ReactGA from 'react-ga';
ReactGA.initialize('UA-127398027-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class Layout extends React.Component {
	constructor(){
      super()
      this.state = {
         meta: {
            title: "Digital Signage, Queue Management and Web Development",
            description: "Averly is a leader in digital signage, queue management solutions and software / web development in Namibia. Contact us for a demo.",
            canonical: "https://averly.com.na/",
         },
         coverImage: cover,
         coverTitle: "Reimagining companies to improve communication and customer experience.",
         page: "Home",
         getStarted: false,
         styling: {
            title: {
               fontSize: "52px",
               lineHeight: "55px",
               letterSpacing: "-2px",
               background: "rgba(27, 107, 183, 1)"
            }
         }
      }
   }

   componentWillMount(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
   }

   render() {

      return (
      	<div>
					<Helmet>
						<title>{this.state.meta.title}</title>
	 					<meta name="description" content={this.state.meta.description} />
	 					<link rel="canonical" href={this.state.meta.canonical} />
					</Helmet>
					<Header />
					<Cover data={this.state} alt="Averly office reception area" />
					<Content page={this.state.page} />
					<Products />
					<Socials />
					<Footer />
         </div>
      );
   }

}
